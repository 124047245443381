/* Base Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  max-width: 600px; /* Adjust the width as needed */
  margin: 0 auto; /* Center the container */
  padding: 20px; /* Add some padding inside the container */
  
}


.title-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  max-width: 600px; /* Adjust the width as needed */
  margin: 0 auto; /* Center the container */
  padding: 20px; /* Add some padding inside the container */

}

.title-container p {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  max-width: 600px; /* Adjust the width as needed */
  margin: 0 auto; /* Center the container */
  padding: 20px; /* Add some padding inside the container */
}


.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 600px;
  margin: auto;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.dropdown-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center;
  gap: 10px; /* Adds spacing between the dropdown elements */
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
 
}

.dropdown1{
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  background-color: #00B050;
  color: #ffffff;  
}

.dropdown2{
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  background-color: #0072b0;
  color: #ffffff;
}

.dropdown3{
  padding: 8px;
  border-radius: 4px;
  border: 1px;
  background-color: #c64cd3;
  color: #ffffff;
}

.dropdown3{
  padding: 8px;
  border-radius: 4px;
  border: 1px;
}

.member-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  width: 100%;
}

label {
  text-align: center;
  margin-bottom: 5px;
}

/* Styling for slider track */
input[type="range"] {
  appearance: none;
  width: 100%;
  height: 8px;
  background: var(--track-color);
  outline: none;
  opacity: 0.7;
  transition: opacity .15s ease-in-out;
  border-radius: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 16px;
  height: 16px;
  background: #666;
  cursor: pointer;
  border-radius: 50%;
}

/* Default grey track color */
.grey-slider {
  --track-color:  #659ec1;
}

/* Green track color for scores above 3 */
.green-slider {
  --track-color:  #00B050;
}

.light-green-slider {
  --track-color:  #92D050;
}

.amber-slider {
  --track-color:  #FFC000;
}


/* Red track color for scores below 3 */
.red-slider {
  --track-color: #FF0000;
}



 h3 {
  text-align: center;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

h2 {
  text-align: center;
  margin-bottom: 5px;
  font-family: Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
}

.submit-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center;
  align-items: center; /* Centers the button horizontally */
  width: 100%;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 5px;
  width: 120px;
  height: 50px;
  margin-top: 10px;
}

.button-gc-advice {
  background-color: rgb(119, 203, 115);
  color: white;
  width: 200px;
  margin-bottom: 5px;
  box-shadow: 
        inset 2px 2px 5px #a3f8c6,  /* Top-left shadow (highlight) */
        inset -2px -2px 5px #435e49, /* Bottom-right shadow (dark) */
        2px 2px 5px rgba(0, 0, 0, 0.2); /* Outer shadow for depth */
}

.button-gc-test {
  background-color: rgb(119, 203, 115);
  color: white;
  width: 200px;
}

.button-al-advice {
  background-color: rgb(115, 185, 203);
  color: white;
  width: 200px;
  margin-bottom: 5px;
  box-shadow: 
        inset 2px 2px 5px #a4baf8,  /* Top-left shadow (highlight) */
        inset -2px -2px 5px #46435e, /* Bottom-right shadow (dark) */
        2px 2px 5px rgba(0, 0, 0, 0.2); /* Outer shadow for depth */
}

.button-al-test {
  background-color: rgb(115, 185, 203);
  color: white;
  width: 200px;
}

.button-dg-advice {
  background-color: rgb(112, 112, 206);
  color: white;
  width: 200px;
  margin-bottom: 5px;
  box-shadow: 
        inset 2px 2px 5px #a4baf8,  /* Top-left shadow (highlight) */
        inset -2px -2px 5px #46435e, /* Bottom-right shadow (dark) */
        2px 2px 5px rgba(0, 0, 0, 0.2); /* Outer shadow for depth */
}

.button-dg-test {
  background-color: rgb(112, 112, 206);
  color: white;
  width: 200px;
  margin-bottom: 5px;
  
}

.button-fj-advice {
  background-color: rgb(109, 127, 229);
  color: white;
  width: 200px;
  margin-bottom: 5px;
  box-shadow: 
        inset 2px 2px 5px #a4baf8,  /* Top-left shadow (highlight) */
        inset -2px -2px 5px #46435e, /* Bottom-right shadow (dark) */
        2px 2px 5px rgba(0, 0, 0, 0.2); /* Outer shadow for depth */
  
}

.button-fj-test {
  background-color: rgb(109, 127, 229);
  color: white;
  width: 200px;
}

.button-mc-advice {
  background-color: rgb(195, 112, 206);
  color: white;
  width: 200px;
  box-shadow: 
        inset 2px 2px 5px #f1a7ee,  /* Top-left shadow (highlight) */
        inset -2px -2px 5px #7c5252, /* Bottom-right shadow (dark) */
        2px 2px 5px rgba(0, 0, 0, 0.2); /* Outer shadow for depth */
}

.button-mc-test {
  background-color: rgb(195, 112, 206);
  color: white;
  width: 200px;
}



button:hover {
  background-color: #0056b3;
}

.analysis-container {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  width: 100%;
  max-width: 600px;
  height: 700px;  /* Set to a specific height */
  overflow: auto; /* Add scrollbars when content overflows */

  
}

.analysis-container textarea {
  width: 100%;
  height: 450px;  /* Set to match the container height */
  resize: none;  /* Prevent resizing if desired */
  font-family: Arial, sans-serif;  /* Set the font to Arial */
  font-size: small;
  overflow: hidden; /* Hide the scroll bar */
  overflow: auto; /* Add scrollbars when content overflows */
  
}

/* Slider Container Styles */
.member-container {
  width: 100%;
  justify-content:space-between;
  max-width: 600px;
  margin: 0px auto;
  position: relative;
  
}

.contact-container {
  width: 100%;
  justify-content:space-between;
  max-width: 600px;
  margin: 20px auto;
  position: relative;
  
}

.terms-container {
  width: auto;          /* Adjusts the width to fit its content */
  display: inline-block; /* Aligns the terms container next to the button */
  margin-right: 10px;    /* Adds some space between the terms-container and the button */
  vertical-align: middle; /* Aligns the checkbox and label vertically with the button */
  margin-bottom: px;
}


.terms-container label {
  margin-left: 8px;
  float: center;
  width: 20px
}

.submit-container button {

    vertical-align: middle; /* Ensures the button aligns vertically with the terms-container */
}

.about-us-container {
  width: 100%;
  justify-content:space-between;
  max-width: 600px;
  margin: 1px auto;
  position: relative;
  margin-top: 0;
  
}

.about-us-image {
  width: 100%; /* Adjust image width */
  max-width: 125px;
  height: auto; /* Maintain aspect ratio */
  margin-top: 0; /* Space between image and text */
  margin-bottom: 0; /* Bring image closer to the text */
  margin-right: 15px; /* Bring image closer to the text */
  margin-left: 0; /* Bring image closer to the text */
 
  float: above; /* Make sure the image aligns left to the text */
}

.disclaimer-container {
  width: 100%;
  justify-content:space-between;
  max-width: 600px;
  margin: 20px auto;
  position: relative;
  
}








.slider-labels {
  width: 100%;
  display:flex;
  justify-content:space-between;
  position: relative;
  top: 15px; /* Adjust as needed */
  padding: 10 5px;
}

.slider-labels span {
  font-size: 14px;
  font-weight: bold;
  display:flex;
  justify-content:center;
  margin-top: 0px;
  margin-bottom: 15px;
  padding: 0 10px;
  
}


/* Responsive Styles */
@media (max-width: 600px) {
  .form-container, .analysis-container {
    padding: 10px;
    border: none;
  }

  .member-container {
    flex-direction: column;
    align-items: flex-start;
  }

  input[type="range"] {
    margin-bottom: 10px;
  }

  button {
    width: 100%;
    padding: 15px;
    font-size: 18px;
  }

  .title-container {
    padding: 0 20px;
  }

  .title-container p {
    font-size: 14px;
  }

  .slider-labels span {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.footer {
  margin-top: 40px;
  padding: 20px;
  background-color: #ffffff;
  text-align: center;
}

.footer-links {
  list-style-type: none;
  padding: 0;
}

.footer-links li {
  display: inline;
  margin: 0 15px;
  cursor: pointer;
  color: #0066cc;
}

.footer-links li:hover {
  text-decoration: underline;
}